import React from 'react';
import { motion } from "framer-motion";
import {
    Camera,
    Upload,
    Share2,
    Users,
    CreditCard,
    ChevronRight,
    PlayCircle,
    DollarSign
} from 'lucide-react';
import { Button } from "../ui/Button";

const steps = [
    {
        icon: Users,
        title: "Create Your Account",
        description: "Sign up on Pixsar Media and join our network of professionals in the media and entertainment industry. Setting up your account is quick and easy, getting you ready to showcase your content to a global audience.",
        color: "bg-purple-100 text-purple-600"
    },
    {
        icon: Camera,
        title: "Configure Your Equipment",
        description: "Integrate and configure your photography or videography equipment with the Pixsar Media platform. Our system supports multiple device types, ensuring seamless uploads and compatibility with professional cameras.",
        color: "bg-blue-100 text-blue-600"
    },
    {
        icon: DollarSign,
        title: "Set Your Pricing",
        description: "Determine the pricing model for your event—whether it's a live concert, wedding, or corporate event. Our platform allows you to set ticket prices or fees for access to each event, maximizing your earning potential.",
        color: "bg-yellow-100 text-yellow-600"
    },
    {
        icon: Upload,
        title: "Upload and Manage Content",
        description: "Upload your media files directly to the platform. Our system organizes and categorizes your content, making it easy for you to monitor what your audience sees and engage with them in real-time.",
        color: "bg-green-100 text-green-600"
    },
    {
        icon: Share2,
        title: "Monitor Audience Engagement",
        description: "Access real-time analytics to see how your audience interacts with your content. Track views, shares, and audience feedback to optimize your content and increase engagement.",
        color: "bg-pink-100 text-pink-600"
    },
    {
        icon: PlayCircle,
        title: "Deliver and Grow Your Reach",
        description: "Share your enhanced content instantly with a global audience. Use Pixsar Media’s secure delivery system and customizable galleries to deliver content to clients and attract new viewers.",
        color: "bg-teal-100 text-teal-600"
    }
];


const features = [
    {
        icon: Users,
        title: "Client Management",
        description: "Organize clients, track preferences, and manage permissions all in one place."
    },
    {
        icon: CreditCard,
        title: "Seamless Payments",
        description: "Accept payments, manage subscriptions, and track revenue effortlessly."
    }
];

const HowItWorks = () => {
    return (
        <div className="bg-white">
            {/* Hero Section */}
            <div className="relative py-32 overflow-hidden">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center max-w-3xl mx-auto">
                        <motion.h1
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            className="text-4xl md:text-5xl font-bold text-gray-900 mb-6"
                        >
                            Transform Your Photography Business
                        </motion.h1>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="text-xl text-gray-600 mb-8"
                        >
                            Simple, powerful tools to streamline your workflow and delight your clients
                        </motion.p>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="flex justify-center space-x-4"
                        >
                            <Button
                                onClick={() => window.open("https://dashboard.pixsar.io/auth", "_blank")}
                                size="lg"
                                className="bg-primary text-white hover:bg-primary/90"
                            >
                                Get Started
                                <ChevronRight className="ml-2 h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                size="lg"
                                className="flex items-center"
                            >
                                <PlayCircle className="mr-2 h-4 w-4" />
                                Watch Demo
                            </Button>
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Steps Section */}
            <div className="py-20 bg-gray-50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="text-center max-w-3xl mx-auto mb-16"
                    >
                        <h2 className="text-3xl font-bold text-gray-900 mb-4">
                            Six Simple Steps to Success
                        </h2>
                        <p className="text-gray-600">
                            Our streamlined process makes it easy to deliver professional content to your clients
                        </p>
                    </motion.div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {steps.map((step, index) => (
                            <motion.div
                                key={step.title}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className="relative"
                            >
                                <div className="bg-white rounded-2xl p-6 shadow-lg h-full">
                                    <div className={`w-12 h-12 rounded-lg ${step.color} flex items-center justify-center mb-4`}>
                                        <step.icon className="w-6 h-6" />
                                    </div>
                                    <h3 className="text-xl font-semibold text-gray-900 mb-3">
                                        {step.title}
                                    </h3>
                                    <p className="text-gray-600">
                                        {step.description}
                                    </p>
                                </div>
                                {index < steps.length - 1 && (
                                    <div className="hidden lg:block absolute top-1/2 right-0 transform translate-x-[150%] -translate-y-1/2">
                                        <div className="flex items-center space-x-4">
                                            <div className="w-8 h-[2px] bg-gray-200"></div>
                                            <ChevronRight className="w-6 h-6 text-gray-400" />
                                            <div className="w-8 h-[2px] bg-gray-200"></div>
                                        </div>
                                    </div>
                                )}
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div className="py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                        >
                            <img
                                src="/api/placeholder/600/400"
                                alt="Dashboard Preview"
                                className="rounded-2xl shadow-2xl"
                            />
                        </motion.div>
                        <div>
                            <motion.h2
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5 }}
                                className="text-3xl font-bold text-gray-900 mb-6"
                            >
                                Everything You Need in One Place
                            </motion.h2>
                            <div className="space-y-8">
                                {features.map((feature, index) => (
                                    <motion.div
                                        key={feature.title}
                                        initial={{ opacity: 0, y: 20 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        viewport={{ once: true }}
                                        transition={{ duration: 0.5, delay: index * 0.1 }}
                                        className="flex items-start space-x-4"
                                    >
                                        <div className="bg-primary/10 p-3 rounded-lg">
                                            <feature.icon className="w-6 h-6 text-primary" />
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-gray-900 mb-2">
                                                {feature.title}
                                            </h3>
                                            <p className="text-gray-600">
                                                {feature.description}
                                            </p>
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="bg-primary py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="max-w-3xl mx-auto"
                    >
                        <h2 className="text-3xl font-bold text-white mb-6">
                            Ready to Transform Your Photography Business?
                        </h2>
                        <p className="text-white/80 mb-8 text-lg">
                            Join thousands of photographers who are growing their business with Pixsar Media
                        </p>
                        <Button
                            onClick={() => window.open("https://dashboard.pixsar.io/auth", "_blank")}
                            size="lg"
                            variant="secondary"
                            className="text-primary hover:text-primary/90"
                        >
                            Get Started Now
                            <ChevronRight className="ml-2 h-4 w-4" />
                        </Button>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
