import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
    title?: string;
    description?: string;
    keywords?: string;
    author?: string;
    ogImage?: string;
    ogType?: string;
    twitterCard?: string;
    canonicalUrl?: string;
}

const SEO: React.FC<SEOProps> = ({
                                     title = "Pixsar Media - AI-Powered Photography & Videography Platform",
                                     description = "Empower your photography and videography business with Pixsar Media's AI-driven tools. Streamline content delivery, enhance engagement, and grow your audience.",
                                     keywords = "photography, videography, AI photography, content delivery, event photography, wedding photography, photo editing, video editing",
                                     author = "Pixsar Media",
                                     ogImage = "https://pixsar.io/logo.png",
                                     ogType = "website",
                                     twitterCard = "summary_large_image",
                                     canonicalUrl = "https://pixsar.io", // Replace with your actual domain
                                 }) => {
    // Format the title to include your brand name
    const formattedTitle = title.includes("Pixsar") ? title : `${title} | Pixsar Media`;

    return (
        <Helmet>
            {/* Essential Meta Tags */}
            <title>{formattedTitle}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="author" content={author} />

            {/* Viewport Meta Tag */}
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={formattedTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:type" content={ogType} />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:site_name" content="Pixsar Media" />

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content={twitterCard} />
            <meta name="twitter:site" content="@pixsarmedia" />
            <meta name="twitter:title" content={formattedTitle} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={ogImage} />

            {/* Canonical URL */}
            <link rel="canonical" href={canonicalUrl} />

            {/* Favicon Tags */}
            <link rel="icon" href="/icons/favicon.ico" />
            <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />

            {/* Additional Meta Tags */}
            <meta name="theme-color" content="#1A0339" />
            <meta name="msapplication-TileColor" content="#1A0339" />
            <meta name="robots" content="index, follow" />
        </Helmet>
    );
};

const SchemaMarkup: React.FC = () => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Pixsar Media",
        "alternateName": "Pixsar",
        "url": "https://pixsar.io",
        "logo": "https://pixsar.io/logo.png",
        "image": "https://pixsar.io/logo.png",
        "description": "Pixsar Media, An AI-powered photography and videography platform for professional content creators",
        "sameAs": [
            "https://www.facebook.com/pixsarmedia",
            "https://x.com/PixsarMedia",
            "https://www.instagram.com/pixsar_media",
            "https://youtube.com/@pixsarmedia"
        ],
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "MTK Building, Lukuli Rd",
            "addressLocality": "MTK Building, Lukuli Rd",
            "postalCode": "256",
            "addressCountry": "UG"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+256 200 912-077",
            "contactType": "customer service",
            "email": "info@pixsar.io",
            "availableLanguage": ["English"]
        }
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(schema)}
            </script>
        </Helmet>
    );
};

export { SEO, SchemaMarkup };