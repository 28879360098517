import React from 'react';
import { motion } from "framer-motion";
import {
    Facebook,
    Twitter,
    Instagram,
    Youtube,
    Mail,
    MapPin,
    Phone,
    ArrowUpRight,
    Heart
} from 'lucide-react';
import Logo from "../assets/images/pixsar-logo-purple.svg";

const footerLinks = {
    company: [
        { name: 'About Us', href: '#' },
        { name: 'Careers', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'News', href: '#' },
        { name: 'Contact', href: '#' }
    ],
    product: [
        { name: 'Features', href: '#' },
        { name: 'Pricing', href: '#' },
        { name: 'Case Studies', href: '#' },
        { name: 'Reviews', href: '#' },
        { name: 'Updates', href: '#' }
    ],
    support: [
        { name: 'Help Center', href: '#' },
        { name: 'Terms of Service', href: '#' },
        { name: 'Privacy Policy', href: '#' },
        { name: 'Status', href: '#' },
        { name: 'Report a Bug', href: '#' }
    ]
};

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-white pt-20 pb-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Newsletter Section - Now in its own row */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    className="mb-20"
                >
                    <div className="max-w-3xl mx-auto text-center">
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                            Stay Updated with Pixsar
                        </h3>
                        <p className="text-gray-600 mb-6">
                            Subscribe to our newsletter for the latest updates, features, and photography insights.
                        </p>
                        <form className="max-w-md mx-auto flex gap-3">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="flex-1 px-4 py-3 bg-white rounded-lg border border-gray-200
                                          text-gray-900 placeholder:text-gray-400 focus:outline-none
                                          focus:ring-2 focus:ring-primary focus:border-transparent"
                            />
                            <button
                                type="submit"
                                className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90
                                         transition-colors flex-shrink-0 font-medium"
                            >
                                Subscribe
                            </button>
                        </form>
                    </div>
                </motion.div>

                {/* Main Footer Content */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-8 pb-12 border-b border-gray-200">
                    {/* Company Info */}
                    <div className="lg:col-span-4">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                        >
                            {/* Logo */}
                            <div className="flex items-center space-x-2 mb-6">
                                <img
                                    src={Logo}
                                    alt="Pixsar Logo"
                                    className="h-20"
                                />
                            </div>

                            {/* Description */}
                            <p className="text-gray-600 mb-8 max-w-sm">
                                Empowering photographers and videographers with AI-driven tools to deliver
                                exceptional content and grow their business.
                            </p>

                            {/* Contact Info */}
                            <div className="space-y-4">
                                <a href="mailto:info@pixsar.io" className="flex items-center space-x-3 text-gray-600 hover:text-primary transition-colors">
                                    <Mail className="w-5 h-5" />
                                    <span>info@pixsar.io</span>
                                </a>
                                <a href="tel:+256200912077" className="flex items-center space-x-3 text-gray-600 hover:text-primary transition-colors">
                                    <Phone className="w-5 h-5" />
                                    <span>+256 200 912-077</span>
                                </a>
                                <div className="flex items-center space-x-3 text-gray-600">
                                    <MapPin className="w-5 h-5" />
                                    <span>MTK Building, Lukuli Rd</span>
                                </div>
                            </div>
                        </motion.div>
                    </div>

                    {/* Quick Links - Now spans full remaining width */}
                    <div className="lg:col-span-8 grid grid-cols-2 sm:grid-cols-3 gap-8">
                        {/* Company Links */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.1 }}
                        >
                            <h3 className="text-gray-900 font-semibold mb-4">Company</h3>
                            <ul className="space-y-3">
                                {footerLinks.company.map((link) => (
                                    <li key={link.name}>
                                        <a
                                            href={link.href}
                                            className="text-gray-600 hover:text-primary transition-colors inline-flex items-center group"
                                        >
                                            {link.name}
                                            <ArrowUpRight className="w-3 h-3 ml-1 opacity-0 -translate-y-1 translate-x-1 group-hover:opacity-100 group-hover:translate-y-0 group-hover:translate-x-0 transition-all" />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </motion.div>

                        {/* Product Links */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            <h3 className="text-gray-900 font-semibold mb-4">Product</h3>
                            <ul className="space-y-3">
                                {footerLinks.product.map((link) => (
                                    <li key={link.name}>
                                        <a
                                            href={link.href}
                                            className="text-gray-600 hover:text-primary transition-colors inline-flex items-center group"
                                        >
                                            {link.name}
                                            <ArrowUpRight className="w-3 h-3 ml-1 opacity-0 -translate-y-1 translate-x-1 group-hover:opacity-100 group-hover:translate-y-0 group-hover:translate-x-0 transition-all" />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </motion.div>

                        {/* Support Links */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                        >
                            <h3 className="text-gray-900 font-semibold mb-4">Support</h3>
                            <ul className="space-y-3">
                                {footerLinks.support.map((link) => (
                                    <li key={link.name}>
                                        <a
                                            href={link.href}
                                            className="text-gray-600 hover:text-primary transition-colors inline-flex items-center group"
                                        >
                                            {link.name}
                                            <ArrowUpRight className="w-3 h-3 ml-1 opacity-0 -translate-y-1 translate-x-1 group-hover:opacity-100 group-hover:translate-y-0 group-hover:translate-x-0 transition-all" />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </motion.div>
                    </div>
                </div>

                {/* Bottom Bar remains the same */}
                <div className="pt-8 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0">
                    {/* Copyright */}
                    <div className="text-gray-600 text-sm flex items-center">
                        <span>© {currentYear} Pixsar. Made with</span>
                        <Heart className="w-4 h-4 mx-1 text-red-500" />
                        <span>for creators</span>
                    </div>

                    {/* Social Links */}
                    <div className="flex space-x-6">
                        {[
                            { icon: Facebook, href: 'https://www.facebook.com/pixsarmedia' },
                            { icon: Twitter, href: 'https://x.com/PixsarMedia' },
                            { icon: Instagram, href: 'https://www.instagram.com/pixsar_media/profilecard' },
                            { icon: Youtube, href: 'https://youtube.com/@pixsarmedia' }
                        ].map((social, index) => (
                            <motion.a
                                key={index}
                                href={social.href}
                                target="_blank"
                                className="text-gray-400 hover:text-primary transition-colors"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <social.icon className="w-5 h-5" />
                            </motion.a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;