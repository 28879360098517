import React from 'react';
import { motion } from "framer-motion";
import { Button } from "../ui/Button";
import { Send } from 'lucide-react';
import HeroBgImage from "../../assets/images/hero-bg.png";
import HeroImage from "../../assets/images/hero-image.png";

const Hero = () => {
    const dashboardUrl = "https://dashboard.pixsar.io/auth";

    return (
        <div className="relative bg-background overflow-hidden">
            {/* Background Image */}
            <div
                className="absolute inset-0 before:absolute before:inset-0 before:bg-background/90 before:z-10"
            >
                <img
                    src={HeroBgImage}
                    alt="Background"
                    className="w-full h-full object-cover opacity-20"
                />
            </div>

            {/* Main Content */}
            <div className="relative z-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 md:pb-16">
                <div className="grid lg:grid-cols-2 gap-12 items-center">
                    {/* Left Content */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="relative z-20"
                    >
                        <h1 className="text-5xl md:text-6xl font-bold text-secondary mb-6 leading-tight">
                            Broadcast Anywhere, Anytime, and Maximize Your Revenue!
                        </h1>
                        <p className="text-muted-foreground text-lg mb-8 max-w-lg leading-relaxed">
                            Join Pixsar Media to Expand Your Business! As a photographer, videographer,
                            or media company, gain access to powerful
                            tools that help you reach a wider audience, increase client engagement,
                            and drive more bookings and revenue.
                        </p>
                        <Button
                            variant="secondary"
                            onClick={() => window.open(dashboardUrl, '_blank')}
                            className="hover:bg-secondary/90 text-primary px-6 py-6 rounded-lg flex items-center space-x-2 text-lg shadow-lg hover:shadow-xl transition-all duration-300"
                        >
                            <span>Get Started Now</span>
                            <Send className="w-5 h-5" />
                        </Button>
                    </motion.div>

                    {/* Right Content - Hero Image */}
                    <motion.div
                        className="hidden lg:block relative"
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                    >
                        <div className="relative h-[600px] w-full">
                            {/* Decorative circle background */}
                            <div className="absolute right-0 top-1/2 -translate-y-1/2 w-[500px] h-[500px] rounded-full bg-purple-800/20 blur-3xl" />

                            {/* Main Image Container */}
                            <div className="relative h-full flex items-center justify-end">
                                <div className="w-[90%] h-[90%] rounded-[2.5rem] overflow-hidden shadow-2xl">
                                    <img
                                        src={HeroImage}
                                        alt="Hero"
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>

                {/* Companies Section */}
                <motion.div
                    className="mt-20"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                >
                    <p className="text-muted-foreground text-center mb-8 text-lg">
                        Join 100+ Companies already using Pixsar
                    </p>
                    {/*TODO change these when we have on-boarded companies*/}
                    {/*<div className="flex flex-wrap justify-center items-center gap-8 md:gap-12 lg:gap-16">*/}
                    {/*    {['Waverio', 'Logoipsum', 'Alterbone', 'Tinygone', 'Preso'].map((company, index) => (*/}
                    {/*        <motion.div*/}
                    {/*            key={company}*/}
                    {/*            initial={{ opacity: 0 }}*/}
                    {/*            animate={{ opacity: 1 }}*/}
                    {/*            transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}*/}
                    {/*            className="text-secondary text-xl font-semibold opacity-70 hover:opacity-100 transition-opacity duration-300"*/}
                    {/*        >*/}
                    {/*            {company}*/}
                    {/*        </motion.div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                </motion.div>
            </div>
        </div>
    );
};

export default Hero;