import React from 'react';
import { motion } from "framer-motion";
import { ArrowUpRight } from 'lucide-react';
import Image from "../../assets/images/seamless-section-image.png"

const SeamlessContent = () => {
    return (
        <div className="w-full bg-white py-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid lg:grid-cols-2 gap-16 items-center">
                    {/* Left Content */}
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}
                    >
                        <h2 className="text-4xl lg:text-5xl font-bold text-primary mb-8 leading-tight">
                            Seamless Content Delivery, Happy Clients
                        </h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.4, duration: 0.8 }}
                            className="text-black text-lg mb-8 leading-relaxed"
                        >
                            Delivering exceptional service to clients is at the heart of any successful event company.
                            Pixsar Media gives you the tools to provide instant coverage to your clients, whether it's
                            live-streaming a wedding, sharing short clips from a conference, or delivering high-quality
                            images directly to the attendees. Not only do you impress clients with your efficiency,
                            but you also provide an enhanced experience for attendees—both at the event and remotely.
                            Keep your clients happy and come back for more with our all-in-one platform.
                        </motion.p>

                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.6, duration: 0.8 }}
                        >
                            <a
                                href="https://dashboard.pixsar.io/auth"
                                target="_blank"
                                className="inline-flex items-center text-lg font-semibold text-primary hover:text-primary/80 transition-colors group"
                            >
                                Get Onboard and simplify your event coverage
                                <motion.span
                                    className="ml-2 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform duration-300"
                                >
                                    <ArrowUpRight className="w-5 h-5" />
                                </motion.span>
                            </a>
                        </motion.div>
                    </motion.div>

                    {/* Right Content - Animated Images */}
                    <motion.div
                        className="relative"
                        initial={{ opacity: 0, scale: 0.95 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}
                    >
                        <div className="relative">
                            <div className="relative rounded-3xl overflow-hidden">
                                <motion.div
                                    className="relative z-10"
                                    initial={{ y: 20, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: 0.4, duration: 0.8 }}
                                >
                                    <img
                                        src={Image}
                                        alt="Happy Clients"
                                        className="w-full h-auto rounded-3xl shadow-2xl"
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default SeamlessContent;