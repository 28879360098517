import React from 'react';
import { motion } from "framer-motion";
import Image from "../../assets/images/conference-illustration.png";

const features = [
    {
        id: 1,
        title: "Live Streaming",
        description: "Broadcast high-quality, low-latency live streams to global audiences," +
            " creating an engaging and immersive experience for viewers"
    },
    {
        id: 2,
        title: "Live Photos",
        description: "Capture and share live photos directly from events, enabling real-time" +
            " visual updates and additional engagement for those who couldn't attend"
    },
    {
        id: 3,
        title: "AI Content Enhancement",
        description: "Automatically improve event quality using AI for real-time edits"
    },
    {
        id: 4,
        title: "Monetization",
        description: "Seamless tools for tickets, ads, and virtual gifts"
    }
];

const WhyChooseSection = () => {
    return (
        <div className="w-full bg-background py-24 overflow-hidden">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid lg:grid-cols-2 gap-12 items-center">
                    {/* Left Content */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}
                    >
                        <h2 className="text-4xl lg:text-5xl font-bold text-secondary mb-4">
                            Why Choose Pixsar Media?
                        </h2>
                        <p className="text-muted-foreground text-lg mb-12">
                            A brief intro about the AI-driven features and how Pixsar Media stands out
                        </p>

                        {/* Features Grid */}
                        <div className="grid sm:grid-cols-2 gap-8">
                            {features.map((feature, index) => (
                                <motion.div
                                    key={feature.id}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.1 }}
                                    className="bg-accent/10 rounded-2xl p-6 backdrop-blur-sm hover:bg-accent/20 transition-colors duration-300"
                                >
                                    <h3 className="text-xl font-semibold text-secondary mb-3">
                                        {feature.title}
                                    </h3>
                                    <p className="text-muted-foreground">
                                        {feature.description}
                                    </p>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>

                    {/* Right Content - SVG Illustration */}
                    <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}
                        className="relative"
                    >
                        <img
                            src={Image}
                            alt="Why choose us illustration"
                            className="w-full h-auto rounded-3xl shadow-2xl"
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseSection;